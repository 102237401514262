const {
  withPropsDeserializer: deserialize,
} = require('@wix/native-components-infra/dist/es/src/HOC/propsDeserializer/propsDeserializer');
const {
  withSentryErrorBoundary: sentry,
} = require('@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary');
const {SLIDER_GALLERY_DSN: DSN} = require('@wix/wixstores-client-core/dist/es/src/viewer-script/sentryConf');
const {getWrappedComponent: get} = require('../galleryAppConfig');
const {SliderGallery} = require('../components/SliderGallery/SliderGallery');

const sliderGallery = get({entryName: 'sliderGallery', Component: SliderGallery});

module.exports = {
  default: {
    component: deserialize(
      sentry(sliderGallery, {
        dsn: DSN,
        config: {environment: 'Native Component'},
      })
    ),
  },
};
